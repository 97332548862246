import { MinWidth } from "@/ui/media";
import { Dialog, DialogStore } from "@ariakit/react";
import { useMediaQuery } from "@indietabletop/appkit/use-media-query";
import { useCallback } from "react";
import { ExternalLink } from "../external-link";
import { Link } from "../link";
import { useCurrentUser } from "../store/hooks";
import { interactiveTextWhite } from "../utils.css";
import { RulesetVersionIndicator } from "../version-indicator";
import * as css from "./style.css";

function Footer() {
  const isLoggedIn = !!useCurrentUser();

  return (
    <footer className={css.footer}>
      <div className={css.footerInfo}>
        <RulesetVersionIndicator />

        <div>
          <Link className={interactiveTextWhite} href="/settings">
            Settings
          </Link>

          <span className={css.middot}> · </span>

          <Link className={interactiveTextWhite} href="/changelog">
            Changelog
          </Link>

          <span className={css.middot}> · </span>

          <Link className={interactiveTextWhite} href="/help">
            Help
          </Link>

          <span className={css.middot}> · </span>

          {!isLoggedIn ?
            <>
              <Link className={interactiveTextWhite} href="/join">
                Join
              </Link>

              <span className={css.middot}> · </span>

              <Link className={interactiveTextWhite} href="/login">
                Log in
              </Link>
            </>
          : <Link className={interactiveTextWhite} href="/account">
              Account
            </Link>
          }
        </div>
        <div>
          <ExternalLink
            className={interactiveTextWhite}
            href="https://trello.com/b/LjbD3ZBN/hobgoblin"
          >
            Roadmap
          </ExternalLink>

          <span className={css.middot}> · </span>

          <ExternalLink
            className={interactiveTextWhite}
            href="https://discord.gg/g9Cugahmgq"
          >
            Discord
          </ExternalLink>
        </div>
      </div>

      <ExternalLink
        href="https://www.electi-studio.com/hobgoblin?utm_source=indietabletopclub&utm_medium=hobgoblinbattlegen"
        className={css.cta}
      >
        Get the Rules
      </ExternalLink>
    </footer>
  );
}

export function Sidebar(props: { store: DialogStore }) {
  const isMatch = useMediaQuery(MinWidth.MEDIUM);

  const closeSidebar = useCallback(() => props.store.hide(), [props.store]);

  return (
    <Dialog {...props} className={css.sidebar} modal={!isMatch}>
      <nav className={css.links}>
        <Link onClick={closeSidebar} href="~/" className={css.logoArea}>
          <img src="/logos/logo-small.png" alt="Home" className={css.logo} />
        </Link>

        <Link onClick={closeSidebar} href="~/army" className={css.link}>
          <h2 className={css.heading}>Army Builder</h2>
          <p className={css.sidebarDescription}>
            Build, print, and share your armies.
          </p>
        </Link>

        <Link onClick={closeSidebar} href="~/battle" className={css.link}>
          <h2 className={css.heading}>Chaos of War</h2>
          <p className={css.sidebarDescription}>
            Generate a battle with one click.
          </p>
        </Link>

        <Link onClick={closeSidebar} href="~/quick-start" className={css.link}>
          <h2 className={css.heading}>Quick Start Rules</h2>
          <p className={css.sidebarDescription}>
            Hobgoblin rules and reference.
          </p>
        </Link>

        <Link onClick={closeSidebar} href="~/fortune" className={css.link}>
          <h2 className={css.heading}>Fortune Cards</h2>
          <p className={css.sidebarDescription}>
            Use playing cards as your Fortune deck.
          </p>
        </Link>
      </nav>

      <Footer />
    </Dialog>
  );
}
