import { FormStoreState, useFormStore } from "@ariakit/react";
import { Failure, Success } from "@indietabletop/appkit/async-op";
import { useState } from "react";
import { FailurePayload } from "../client";

type Validator<T> = (value: T) => string | null;

export function useForm<T extends object, R, F>(props: {
  defaultValues: T;
  validate?: { [K in keyof T]?: Validator<T[K]> };
  onSubmit: (state: FormStoreState<T>) => Promise<Success<R> | Failure<F>>;
  onSuccess?: (value: R, state: FormStoreState<T>) => Promise<void> | void;
  mapFailure: (failure: F) => string;
}) {
  const submitName = "submit";

  const [op, setOp] = useState<Awaited<
    ReturnType<typeof props.onSubmit>
  > | null>(null);

  const form = useFormStore({
    defaultValues: props.defaultValues,
  });

  form.useSubmit(async (state) => {
    const submitOp = await props.onSubmit(state);

    if (submitOp.isFailure) {
      form.setError(submitName, props.mapFailure(submitOp.failure));
    }

    if (submitOp.isSuccess) {
      await props.onSuccess?.(submitOp.value, state);
    }

    setOp(submitOp);
  });

  form.useValidate((state) => {
    if (props.validate) {
      const entries = Object.entries(props.validate) as Array<
        [keyof T & string, Validator<T[keyof T]>]
      >;

      for (const [key, validate] of entries) {
        const value = state.values[key];
        const message = validate(value);

        if (message) {
          form.setError(key, message);
        }
      }
    }
  });

  return { form, submitName, op };
}

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validEmail = (value: string) => {
  if (value && !EMAIL_REGEX.test(value)) {
    return "This doesn't look like a valid email.";
  }

  return null;
};

export const validPassword = (value: string) => {
  if (value) {
    if (value.length < 8) {
      return "A password has to be at least 8 characters long.";
    }

    if (value.length > 256) {
      return "A password cannot be longer than 256 characters.";
    }

    return null;
  }

  return null;
};

export function toKnownFailureMessage(failure: FailurePayload) {
  if (failure.type === "NETWORK_ERROR") {
    return "Could not submit form due to network error. Make sure you are connected to the internet and try again.";
  }

  return "Could not submit form due to an unexpected error. Please refresh the page and try again.";
}
