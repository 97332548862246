import { FormSubmit, useFormContext, useStoreState } from "@ariakit/react";
import { fadeIn } from "../animations.css";
import { LoadingIndicatorV2 } from "../loading-indicator";
import * as common from "../utils.css";
import * as css from "./style.css";

export function FormSubmitButton(props: { label: string }) {
  const form = useFormContext();
  const isSubmitting = useStoreState(form, (s) => s?.submitting);

  return (
    <FormSubmit
      className={common.button}
      style={{ position: "relative", ...common.primaryTheme }}
    >
      <span
        style={{ opacity: isSubmitting ? 0 : 1, transition: "200ms opacity" }}
      >
        {props.label}
      </span>

      {isSubmitting && (
        <div
          className={css.indicatorWrapper}
          style={{
            animation: `${fadeIn} 200ms 200ms both`,
          }}
        >
          <LoadingIndicatorV2 className={css.pending} />
        </div>
      )}
    </FormSubmit>
  );
}
