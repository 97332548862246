import { Form, useStoreState } from "@ariakit/react";
import { Dispatch, SetStateAction, useState } from "react";
import { client } from "../client";
import { FormSubmitButton } from "../form-submit-button";
import { FormSubmitError } from "../form-submit-error";
import {
  toKnownFailureMessage,
  useForm,
  validEmail,
  validPassword,
} from "../hooks/use-form";
import {
  IndieTabletopClubFooter,
  IndieTabletopClubSymbol,
} from "../indie-tabletop-club-brand";
import { FormCheckboxField, FormTextField } from "../inputs";
import { InputsGroup } from "../inputs/group";
import { Link } from "../link";
import { ViewContainer } from "../max-width-container";
import { MobileNavLayout } from "../mobile-nav-layout";
import {
  button,
  formFooter,
  formHeader,
  formSubmitArea,
  heading,
  interactiveText,
  paragraph,
  primaryTheme,
  section,
} from "../utils.css";

type SetStep = Dispatch<SetStateAction<Steps>>;

function InitialStep(props: { setStep: SetStep }) {
  const { form, submitName } = useForm({
    defaultValues: {
      email: "",
      password: "",
      acceptedTos: false,
    },
    validate: {
      email: validEmail,
      password: validPassword,
    },
    onSubmit({ values }) {
      return client.join(values);
    },
    mapFailure(f) {
      if (f.type === "API_ERROR" && f.code === 409) {
        return "This email address is already in use. Please choose a different one, or log in.";
      }

      return toKnownFailureMessage(f);
    },
    onSuccess(value) {
      props.setStep({ type: "SUBMIT_CODE", tokenId: value.tokenId });
    },
  });

  const emailValue = useStoreState(form, (s) => s.values.email);

  return (
    <div>
      <Form store={form}>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />
          <h1 className={heading}>Join Indie Tabletop Club</h1>
          <p className={paragraph}>
            Enter your email address and choose a strong password that is at
            least 8 charaters long. We will send you a one-time code to verify
            your account.
          </p>
        </div>

        <InputsGroup>
          <FormTextField
            name={form.names.email}
            label="Email"
            type="email"
            placeholder="E.g. guruk@rotvarlden.com"
            autoComplete="username"
            required
          />

          <FormTextField
            name={form.names.password}
            label="Password"
            type="password"
            placeholder="Choose a strong password"
            hint="Must be at least 8 characters"
            autoComplete="new-password"
            required
          />

          <FormCheckboxField
            name={form.names.acceptedTos}
            required
            label={
              <>
                I accept the{" "}
                <Link href="~/help/terms" className={interactiveText}>
                  Terms of Service
                </Link>
                .
              </>
            }
          />

          <FormSubmitError name={submitName} />
        </InputsGroup>

        <div className={formSubmitArea}>
          <FormSubmitButton label="Join" />
        </div>
      </Form>
      <footer className={formFooter}>
        <p className={paragraph}>
          {"Have an existing account? "}
          <Link
            href="/login"
            className={interactiveText}
            state={{ emailValue }}
          >
            Log in.
          </Link>
        </p>
      </footer>

      <IndieTabletopClubFooter />
    </div>
  );
}

function SubmitCodeStep(props: { tokenId: string; setStep: SetStep }) {
  const { form, submitName } = useForm({
    defaultValues: {
      code: "",
    },
    onSubmit({ values }) {
      return client.verifyUser({
        ...values,
        tokenId: props.tokenId,
      });
    },
    onSuccess() {
      props.setStep({ type: "SUCCESS" });
    },
    mapFailure(failure) {
      if (failure.type === "API_ERROR" && failure.code === 404) {
        return "This code is incorrect or expired. Please try again.";
      }

      return toKnownFailureMessage(failure);
    },
  });

  return (
    <div>
      <Form store={form}>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />
          <h1 className={heading}>Verify Account</h1>
          <p className={paragraph}>
            We've sent a one-time code to the email address you have provided.
            Please, enter the code in the field below to verify your account.
          </p>
        </div>

        <InputsGroup>
          <FormTextField
            name={form.names.code}
            label="Code"
            placeholder="E.g. 123123"
            autoComplete="one-time-code"
            required
          />

          <FormSubmitError name={submitName} />
        </InputsGroup>

        <div className={formSubmitArea}>
          <FormSubmitButton label="Verify" />
        </div>

        <IndieTabletopClubFooter />
      </Form>
    </div>
  );
}

function SuccessStep() {
  return (
    <div>
      <div className={formHeader}>
        <IndieTabletopClubSymbol />
        <h1 className={heading}>Success!</h1>
        <p className={paragraph}>
          Your Indie Tabletop Club account has been verified. Enjoy using the
          Hobgoblin App!
        </p>
      </div>

      <p className={paragraph}>
        <Link href="~/" className={button} style={primaryTheme}>
          Go to dashboard
        </Link>
      </p>

      <IndieTabletopClubFooter />
    </div>
  );
}

type Steps =
  | { type: "INITIAL" }
  | { type: "SUBMIT_CODE"; tokenId: string }
  | { type: "SUCCESS" };

function JoinPageSwitch() {
  const [step, setStep] = useState<Steps>({ type: "INITIAL" });

  switch (step.type) {
    case "INITIAL": {
      return <InitialStep setStep={setStep} />;
    }

    case "SUBMIT_CODE": {
      return <SubmitCodeStep {...step} setStep={setStep} />;
    }

    case "SUCCESS": {
      return <SuccessStep />;
    }
  }
}

export function JoinPage() {
  return (
    <MobileNavLayout title="Join">
      <ViewContainer padding="small">
        <div className={section}>
          <JoinPageSwitch />
        </div>
      </ViewContainer>
    </MobileNavLayout>
  );
}
