import { VersionResolutionError } from "@/lib/game-system";
import {
  InaccessibleDatabaseError,
  QueryError,
} from "@/ui/database-provider/types";
import { AsyncOp, Pending } from "@indietabletop/appkit/async-op";
import { createContext, useContext } from "react";
import { UserSettings } from "../../lib/user-settings";

export const UserSettingsContext = createContext<
  AsyncOp<
    UserSettings,
    QueryError | InaccessibleDatabaseError | VersionResolutionError
  >
>(new Pending());

export function useUserSettingsOp() {
  return useContext(UserSettingsContext);
}
