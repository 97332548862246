import checkmark from "@/images/checkmark.svg";
import { Image } from "@/ui/image";
import {
  FormCheckbox,
  FormCheckboxProps,
  FormError,
  FormInput,
  FormInputProps,
  FormLabel,
} from "@ariakit/react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import {
  ChangeEventHandler,
  FocusEventHandler,
  useId,
  type ReactNode,
} from "react";
import * as css from "./style.css";

export function FormCheckboxField(
  props: FormCheckboxProps & { label: ReactNode },
) {
  const { label, name, ...inputProps } = props;

  return (
    <div>
      <FormCheckbox name={name} {...inputProps} />{" "}
      <FormLabel name={name}>{label}</FormLabel>
      <FormError name={name} className={css.issue} />
    </div>
  );
}

export function FormTextField(
  props: FormInputProps & {
    label: string;
    hint?: ReactNode;
  },
) {
  const { name, label, hint, ...inputProps } = props;

  return (
    <div>
      <div className={css.field}>
        <FormLabel name={name} className={css.fieldLabel}>
          {label}
        </FormLabel>

        <FormInput {...inputProps} name={name} className={css.fieldInput} />
      </div>

      <FormError name={name} className={css.issue} />

      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  );
}

export function TextField(props: {
  label: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  hint?: ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
  type?: "text" | "email" | "password";
}) {
  return (
    <div>
      <label className={css.field}>
        <span className={css.fieldLabel}>{props.label}</span>

        <input
          type={props.type ?? "text"}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          readOnly={props.readOnly}
          disabled={props.disabled}
          className={css.fieldInput}
        />
      </label>

      {props.hint && <div className={css.hint}>{props.hint}</div>}
    </div>
  );
}

export function TextAreaField(props: {
  label: string;
  value: string;
  minRows?: number;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  placeholder: string;
}) {
  const lines = props.value.split("\n");

  return (
    <label className={css.field}>
      <span className={css.fieldLabel}>{props.label}</span>

      <span
        className={css.fieldTextAreaContainer}
        style={assignInlineVars({
          [css.minRows]: `${props.minRows ?? 2}`,
        })}
      >
        <span aria-hidden="true" className={css.fieldTextAreaSpacer}>
          {lines.map((line, index) => {
            return (
              <span className={css.fieldTextAreaSpacerLine} key={index}>
                {line}
              </span>
            );
          })}
        </span>

        <textarea
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          className={css.fieldTextArea}
        />
      </span>
    </label>
  );
}

export function RadioGroupField(props: {
  label?: string;
  options: Array<{
    label: string;
    extra?: string;
    value: string;
    checked: boolean;
    onChange: () => void;
  }>;
}) {
  const name = useId();

  return (
    <div>
      {props.label && <div className={css.fieldLabel}>{props.label}</div>}

      <div className={css.radioGroup}>
        {props.options.map((option) => {
          return (
            <label key={option.label} className={css.radioOption}>
              <span className={css.checkboxContainer}>
                <input
                  className={css.radioInput}
                  name={name}
                  type="radio"
                  value={option.value}
                  checked={option.checked}
                  onChange={option.onChange}
                />

                <Image
                  src={checkmark}
                  alt=""
                  className={css.checkboxMark}
                  width={36}
                  height={36}
                />
              </span>

              <span className={css.inputLabel}>{option.label}</span>

              {option.extra && (
                <span className={css.inputExtra}>{option.extra}</span>
              )}
            </label>
          );
        })}
      </div>
    </div>
  );
}
