import { Store } from "@xstate/store";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyStore<T> = Store<T, any, any>;

export function withLocalStorage<
  PersistedContext,
  Context extends PersistedContext,
>({
  store,
  key,
  getPersistedContext,
  onStorage,
}: {
  /**
   * Store to persist in localStorage.
   */
  store: AnyStore<Context>;

  /**
   * The localStorage key where the store should be saved.
   */
  key: string;

  /**
   * Returns data to be stored in localStorage given store context.
   *
   * This can be a subset of the data that is stored in the in-memory context.
   */
  getPersistedContext: (context: NoInfer<Context>) => PersistedContext;

  /**
   * Any side-effects to run in response to the "storage" window event.
   *
   * It is a good idea to check whether the incoming context is compatible with
   * the current in-memory context, as there are no guarantees that it will.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
   */
  onStorage: (value: unknown) => void;
}) {
  // const { context: initialContext } = store.getInitialSnapshot();
  // const persistedContext = getPersistedContext(initialContext);
  // localStorage.setItem(key, JSON.stringify(persistedContext));

  store.subscribe((snapshot) => {
    const persistedContext = getPersistedContext(snapshot.context);
    localStorage.setItem(key, JSON.stringify(persistedContext));
  });

  // Make sure that in-memory store is synced between browser tabs.
  window.addEventListener("storage", (event) => {
    if (event.key === key && event.newValue) {
      try {
        const persistedContext: unknown = JSON.parse(event.newValue);
        onStorage(persistedContext);
      } catch (cause) {
        const error = new Error(
          `Could not parse value received via 'storage' event at key ${key}.`,
          { cause },
        );

        console.error(error);
      }
    }
  });
}
