import { config } from "@/config";
import electiStudioSrc from "@/images/electi-studio-logo.png";
import itcSrc from "@/images/itc-white.svg";
import { ExternalLink } from "@/ui/external-link";
import { Image } from "@/ui/image";
import { Link } from "@/ui/link";
import { interactiveTextWhite } from "@/ui/utils.css";
import { useCurrentUser } from "../store/hooks";
import * as css from "./style.css";

function getReleaseIdentifier() {
  const shortcode = config.GIT_COMMIT_SHA;
  const branch = config.GIT_COMMIT_REF;

  if (!shortcode) {
    return "[local]";
  }

  return shortcode.slice(0, 7) + (branch ? ` (${branch})` : "");
}

function LegalSection() {
  const Electi_Studio_LLC = (
    <ExternalLink
      href="https://www.electi-studio.com/?utm_source=indietabletopclub&utm_medium=hobgoblinbattlegen"
      className={css.legalLink}
    >
      Electi Studio LLC
    </ExternalLink>
  );

  const Artur_Muller = (
    <ExternalLink
      href="http://arturmuller.com?utm_source=indietabletopclub"
      className={css.legalLink}
    >
      Artur Müller
    </ExternalLink>
  );

  const Indie_Tabletop_Club = (
    <ExternalLink className={css.legalLink} href="https://indietabletop.club">
      Indie Tabletop Club
    </ExternalLink>
  );

  return (
    <p className={css.legalParagraph}>
      All text from “Hobgoblin” by Mike Hutchinson © 2023 {Electi_Studio_LLC}.
      All rights reserved. Used with permission. App development and code
      © 2023 {Artur_Muller} (via {Indie_Tabletop_Club}). All rights reserved.
      Release {getReleaseIdentifier()}.
    </p>
  );
}

export function Footer() {
  const isLoggedIn = !!useCurrentUser();

  return (
    <div className={css.footer}>
      <div className={css.links}>
        <Link className={interactiveTextWhite} href="/changelog">
          Changelog
        </Link>

        <span className={css.middot}> · </span>

        <Link className={interactiveTextWhite} href="/help">
          Help
        </Link>

        <span className={css.middot}> · </span>

        <ExternalLink
          className={interactiveTextWhite}
          href="https://trello.com/b/LjbD3ZBN/hobgoblin"
        >
          Roadmap
        </ExternalLink>

        <span className={css.middot}> · </span>

        <ExternalLink
          className={interactiveTextWhite}
          href="https://discord.gg/g9Cugahmgq"
        >
          Discord
        </ExternalLink>

        <span className={css.middot}> · </span>

        {!isLoggedIn ?
          <Link className={interactiveTextWhite} href="/login">
            Log in
          </Link>
        : <Link className={interactiveTextWhite} href="/account">
            Account
          </Link>
        }
      </div>

      <div className={css.legal}>
        <div className={css.logos}>
          <ExternalLink
            className={css.logoLink}
            href="https://www.electi-studio.com/?utm_source=indietabletopclub&utm_medium=hobgoblinbattlegen"
          >
            <Image
              src={electiStudioSrc}
              alt="Electi Studio"
              width={638}
              height={136}
              className={css.electiStudioImage}
            />
          </ExternalLink>
          <ExternalLink
            className={css.logoLink}
            href="https://indietabletop.club/?utm_source=Hobgoblin"
          >
            <Image
              src={itcSrc}
              alt="Indie Tabletop Club"
              width={194}
              height={12}
              className={css.itcImage}
            />
          </ExternalLink>
        </div>

        <p className={css.legalParagraph}>
          {"This app uses no tracking, other than anonymous, GDPR-compliant "}
          <ExternalLink href="https://usefathom.com" className={css.legalLink}>
            Fathom Analytics
          </ExternalLink>
          {". For more information, see our "}
          <Link href="/help/terms" className={css.legalLink}>
            Terms of Service
          </Link>
          {", "}
          <Link href="/help/privacy" className={css.legalLink}>
            Privacy Policy
          </Link>
          {", and "}
          <Link href="/help/cookies" className={css.legalLink}>
            Cookie Policy
          </Link>
          {"."}
        </p>

        <LegalSection />
      </div>
    </div>
  );
}
