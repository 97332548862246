import { color } from "../tokens";
import * as css from "./style.css";

export type SyncIconStatus = keyof typeof icons;

const icons = {
  syncing: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle fill="#FFFFFF" cx="10" cy="10" r="8" />
      <path
        d="M10,20 C4.47715,20 0,15.5228 0,10 C0,4.47715 4.47715,0 10,0 C15.5228,0 20,4.47715 20,10 C20,15.5228 15.5228,20 10,20 Z M14.8201,15.0761 C16.1628,13.8007 17,11.9981 17,10 C17,6.13401 13.866,3 10,3 C8.9391,3 7.9334,3.23599 7.03241,3.65834 L8.0072,5.41292 C8.6177,5.14729 9.2917,5 10,5 C12.7614,5 15,7.23858 15,10 L12,10 L14.8201,15.0761 Z M12.9676,16.3417 L11.9928,14.5871 C11.3823,14.8527 10.7083,15 10,15 C7.23858,15 5,12.7614 5,10 L8,10 L5.17993,4.92387 C3.83719,6.19929 3,8.0019 3,10 C3,13.866 6.13401,17 10,17 C11.0609,17 12.0666,16.764 12.9676,16.3417 Z"
        fill="#735892"
        fillRule="nonzero"
      />
    </g>
  ),
  synced: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle fill="#FFFFFF" cx="10" cy="10" r="8" />
      <path
        d="M10,20 C4.47715,20 0,15.5228 0,10 C0,4.47715 4.47715,0 10,0 C15.5228,0 20,4.47715 20,10 C20,15.5228 15.5228,20 10,20 Z M9.0026,14 L16.0737,6.92893 L14.6595,5.51472 L9.0026,11.1716 L6.17421,8.3431 L4.75999,9.7574 L9.0026,14 Z"
        fill="#44C260"
        fillRule="nonzero"
      />
    </g>
  ),
  error: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle fill="#FFFFFF" cx="10" cy="10" r="8" />
      <path
        d="M10,20 C4.47715,20 0,15.5228 0,10 C0,4.47715 4.47715,0 10,0 C15.5228,0 20,4.47715 20,10 C20,15.5228 15.5228,20 10,20 Z M10,8.5858 L7.17157,5.75736 L5.75736,7.17157 L8.5858,10 L5.75736,12.8284 L7.17157,14.2426 L10,11.4142 L12.8284,14.2426 L14.2426,12.8284 L11.4142,10 L14.2426,7.17157 L12.8284,5.75736 L10,8.5858 Z"
        fill="#D6446E"
        fillRule="nonzero"
      />
    </g>
  ),
  unsynced: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle fill={color.CHARCOAL_BLUE} cx="10" cy="10" r="8" />
      <path
        d="M10,20 C4.47715,20 0,15.5228 0,10 C0,4.47715 4.47715,0 10,0 C15.5228,0 20,4.47715 20,10 C20,15.5228 15.5228,20 10,20 Z M9,13 L9,15 L11,15 L11,13 L9,13 Z M11,11.3551 C12.4457,10.9248 13.5,9.5855 13.5,8 C13.5,6.067 11.933,4.5 10,4.5 C8.302,4.5 6.88637,5.70919 6.56731,7.31346 L8.5288,7.70577 C8.6656,7.01823 9.2723,6.5 10,6.5 C10.8284,6.5 11.5,7.17157 11.5,8 C11.5,8.8284 10.8284,9.5 10,9.5 C9.4477,9.5 9,9.9477 9,10.5 L9,12 L11,12 L11,11.3551 Z"
        fill="#FFFFFF"
        fillRule="nonzero"
      />
    </g>
  ),
  unverified: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle fill="white" cx="10" cy="10" r="8" />
      <path
        d="M10,20 C4.47715,20 0,15.5228 0,10 C0,4.47715 4.47715,0 10,0 C15.5228,0 20,4.47715 20,10 C20,15.5228 15.5228,20 10,20 Z M9,13 L9,15 L11,15 L11,13 L9,13 Z M11,11.3551 C12.4457,10.9248 13.5,9.5855 13.5,8 C13.5,6.067 11.933,4.5 10,4.5 C8.302,4.5 6.88637,5.70919 6.56731,7.31346 L8.5288,7.70577 C8.6656,7.01823 9.2723,6.5 10,6.5 C10.8284,6.5 11.5,7.17157 11.5,8 C11.5,8.8284 10.8284,9.5 10,9.5 C9.4477,9.5 9,9.9477 9,10.5 L9,12 L11,12 L11,11.3551 Z"
        fill="#f46c37"
        fillRule="nonzero"
      />
    </g>
  ),
};

export function SyncIcon(props: { status: keyof typeof icons; title: string }) {
  const icon = icons[props.status];

  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={props.status === "syncing" ? css.rotate : undefined}
    >
      <title>{props.title}</title>
      {icon}
    </svg>
  );
}
