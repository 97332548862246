import { useFormContext, useStoreState } from "@ariakit/react";
import * as css from "./style.css";

export function FormSubmitError(props: { name: string }) {
  const form = useFormContext();
  const message = useStoreState(form, (s) => {
    return s?.errors[props.name] as string | undefined;
  });

  return (
    <div role="alert" className={css.submitError}>
      {message}
    </div>
  );
}
