import { dot1, dot2, dot3, indicator } from "./style.css";

export function LoadingIndicator() {
  return (
    <div className={indicator}>
      <div className={dot1}>•</div>
      <div className={dot2}>•</div>
      <div className={dot3}>•</div>
    </div>
  );
}

export function LoadingIndicatorV2(props: { className?: string }) {
  const diameter = 10;
  const radius = diameter / 2;
  const gap = diameter;
  const cy = diameter;
  const height = cy * 2;
  const width = diameter * 3 + gap;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      className={props.className}
    >
      <g stroke="none" fill="inherit">
        <circle cx={radius} cy={cy} r={radius} className={dot1} />
        <circle cx={radius * 2 + gap} cy={cy} r={radius} className={dot2} />
        <circle cx={radius * 3 + gap * 2} cy={cy} r={radius} className={dot3} />
      </g>
    </svg>
  );
}
