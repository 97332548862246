import { useDialog } from "@/ui/hooks/use-dialog";
import { useMediaQuery } from "@indietabletop/appkit/use-media-query";
import { ReactNode } from "react";
import { usePathname } from "../hooks/use-pathname";
import { MinWidth } from "../media";
import { Sidebar } from "../sidebar";
import { Spine } from "../spine";
import { NavControlsContext } from "../spine/context";
import * as css from "./style.css";

export function MainLayout(props: { children: ReactNode }) {
  const dialog = useDialog();
  const isRoot = usePathname() === "/";
  const isMatch = useMediaQuery(MinWidth.MEDIUM);

  return (
    <NavControlsContext.Provider value={dialog}>
      <div className={css.root}>
        <Sidebar store={dialog.store} />

        {isMatch && <Spine isRoot={isRoot} />}

        <div className={css.content}>{props.children}</div>
      </div>
    </NavControlsContext.Provider>
  );
}
