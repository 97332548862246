import { DatabaseProvider } from "@/ui/database-provider";
import { ReactNode } from "react";
import { ArmiesRulesetsFetcher } from "../armies-rulesets";
import { CompatGuard } from "../compat-guard";
import { FullscreenDismissBlocker } from "../fullscreen-dismiss-blocker";
import { GameContentLookupProvider } from "../game-content-provider";
import { KeywordsHandler } from "../keywords-handler";
import { ServiceWorkerHandler } from "../service-worker-handler";
import { SessionHandler } from "../session-provider";
import { SyncHandler } from "../sync-handler";
import { UserSettingsFetcher } from "../user-settings";
import "./globals.css";

export function RootLayout(props: { children: ReactNode }) {
  return (
    <CompatGuard>
      <ServiceWorkerHandler>
        <DatabaseProvider>
          <SessionHandler>
            <SyncHandler>
              <GameContentLookupProvider>
                <ArmiesRulesetsFetcher>
                  <UserSettingsFetcher>
                    <KeywordsHandler>
                      {props.children}

                      <FullscreenDismissBlocker />
                    </KeywordsHandler>
                  </UserSettingsFetcher>
                </ArmiesRulesetsFetcher>
              </GameContentLookupProvider>
            </SyncHandler>
          </SessionHandler>
        </DatabaseProvider>
      </ServiceWorkerHandler>
    </CompatGuard>
  );
}
