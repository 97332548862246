import { QueryOp } from "@/ui/database-provider/types";
import { Pending } from "@indietabletop/appkit/async-op";
import { createContext, useContext } from "react";

export const ArmiesRulesetsContext = createContext<QueryOp<Set<string>>>(
  new Pending(),
);

export function useArmiesRulesets() {
  return useContext(ArmiesRulesetsContext);
}
