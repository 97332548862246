import { config } from "@/config";
import { useImmutableFetcher } from "@/ui/hooks/use-fetcher";
import {
  AsyncOp,
  Failure,
  Pending,
  Success,
} from "@indietabletop/appkit/async-op";
import { is, string, type } from "superstruct";

const UserAgent = type({ browser: type({ name: string() }) });

/**
 * Retrieves user agent info from the server.
 *
 * Note that the UA Parser library we are using does work in the client as well,
 * but we only use it in one place, so the single <300 B response is not worth
 * adding an additional ~10 kB dependency to the app.
 */
export function useBrowserName(): AsyncOp<string, unknown> {
  const { data, error } = useImmutableFetcher(`${config.ITC_API_ORIGIN}/ua`);

  if (data) {
    if (is(data, UserAgent)) {
      return new Success(data.browser.name);
    }

    return new Failure("Endpoint response doesn't match User Agent shape.");
  }

  if (error) {
    return new Failure(error);
  }

  return new Pending();
}
