import { Link } from "@/ui/link";
import { fold } from "@indietabletop/appkit/async-op";
import { SectionId } from "../../lib/user-settings";
import { useUserSettingsOp } from "../../ui/user-settings/context";
import { useBattleStateOp } from "../battle-state/context";
import * as css from "../section/style.css";

export function ExclusionsIndicator(props: { sectionId: SectionId }) {
  const battleOp = useBattleStateOp();
  const userSettingsOp = useUserSettingsOp();
  const op = fold([battleOp, userSettingsOp]);

  if (!op.isSuccess) {
    return null;
  }

  const [{ state: battleState }, userSettings] = op.value;

  if (userSettings.ruleset.version !== battleState.ruleset.version) {
    return null;
  }

  const excludedCount = userSettings.getExcluded(props.sectionId)?.length ?? 0;
  if (excludedCount === 0) {
    return null;
  }

  return (
    <Link
      className={css.exclusionsIndicator}
      href={`/settings#${props.sectionId}`}
    >
      {excludedCount} excl.
    </Link>
  );
}
