import { HobgoblinDatabase } from "@/lib/db";
import { TransactionEvent } from "@/oss/modern-idb";
import { Schema } from "@/schema/latest";
import { useDatabase, useDatabaseOpenRequest } from "@/ui/database-provider";
import debounce from "debounce";
import { ReactNode, useCallback, useEffect } from "react";
import useSWR from "swr";
import { client, failureToString } from "../client";
import { store } from "../store";
import { useCurrentUser, useIsSyncAllowed } from "../store/hooks";

function serializeError(error: unknown) {
  return (
    error instanceof Error ? error.message
    : typeof error === "string" ? error
    : "Error could not be serialized."
  );
}

type SyncFnProps = {
  syncTs: number;
  lastSyncTs: number | null;
};

async function trySync(
  database: HobgoblinDatabase,
  syncFn: (database: HobgoblinDatabase, props: SyncFnProps) => Promise<void>,
) {
  try {
    store.send({ type: "SYNC_ONGOING" });

    const syncTs = Date.now();
    const lastSyncTs = store.getSnapshot().context.lastSuccessfulSyncTs;

    await syncFn(database, { syncTs, lastSyncTs });

    // Sync successful, we can move the timestamp forward
    store.send({
      type: "MARK_SUCCESSFUL_SYNC",
      lastSuccessfulSyncTs: syncTs,
    });
  } catch (error) {
    store.send({
      type: "ADD_LOG_ITEM",
      item: {
        type: "SYNC_ERROR",
        error: serializeError(error),
        ts: Date.now(),
      },
    });
  } finally {
    store.send({
      type: "SYNC_COMPLETE",
    });
  }
}

async function pushPull(
  database: HobgoblinDatabase,
  props: SyncFnProps & {
    excludeIds?: Set<string>;
    pullSinceTs?: number;
  },
) {
  const { syncTs, lastSyncTs, excludeIds, pullSinceTs = lastSyncTs } = props;

  const updatedArmies = await database.getUpdatedArmiesSince(lastSyncTs);
  const armiesToPush = updatedArmies
    // Exclude armies specified in exclusion set
    .filter((a) => !excludeIds?.has(a.id))

    // Exclude deleted armies if performing first sync
    .filter((a) => !!lastSyncTs || !a.deleted);

  if (armiesToPush.length > 0) {
    const op = await client.hobgoblinPushAndPullChanges({
      pushTs: syncTs,
      armies: armiesToPush,
      pullSinceTs: pullSinceTs,
    });

    if (op.isFailure) {
      throw new Error(`Push failed: ${failureToString(op.failure)}`);
    }

    const { armies: pulledArmies } = op.value;

    store.send({
      type: "ADD_LOG_ITEM",
      item: { type: "PUSH_SUCCESS", records: armiesToPush, ts: Date.now() },
    });

    if (pulledArmies.length > 0) {
      await database.writeSyncedArmies(pulledArmies);
      store.send({
        type: "ADD_LOG_ITEM",
        item: { type: "PULL_SUCCESS", records: pulledArmies, ts: Date.now() },
      });
    } else {
      store.send({
        type: "ADD_LOG_ITEM",
        item: { type: "PULL_NO_CHANGES", ts: Date.now() },
      });
    }
  } else {
    store.send({
      type: "ADD_LOG_ITEM",
      item: { type: "PUSH_NO_CHANGES", ts: Date.now() },
    });
  }
}

async function pullPushPull(database: HobgoblinDatabase, props: SyncFnProps) {
  const { syncTs, lastSyncTs } = props;

  const op = await client.hobgoblinPullChanges({ sinceTs: lastSyncTs });

  if (op.isFailure) {
    throw new Error(`Pull failed: ${failureToString(op.failure)}`);
  }

  const armies = op.value;

  if (armies.length > 0) {
    await database.writeSyncedArmies(armies);
    store.send({
      type: "ADD_LOG_ITEM",
      item: { type: "PULL_SUCCESS", records: armies, ts: Date.now() },
    });
  } else {
    store.send({
      type: "ADD_LOG_ITEM",
      item: { type: "PULL_NO_CHANGES", ts: Date.now() },
    });
  }

  await pushPull(database, {
    syncTs,
    lastSyncTs,

    // When performing the pushPull internaction during pullPushPull, we only
    // want to pull since the start of the sync process, not since the last
    // sync, as we have just received the full data. This pull only covers the
    // small amount of time between the pull and the push.
    pullSinceTs: syncTs,

    // Given that currently there is no merge step (data from the cloud
    // always overwrites local data), it doesn't make sense to push whatever
    // was just received back up to the cloud after pull, as it will always
    // be the exact same data. Once/if merge is implemented, this set would
    // include only unchanged items, with merged items needing a sync back
    // up the cloud.
    excludeIds: new Set(armies.map((a) => a.id)),
  });
}

/**
 * Listens for successful readwrite transactions on the armies store
 * and pushes changes to the cloud.
 *
 * Note that data push is debounced with a 1500ms delay.
 */
function usePushOnReadWrite() {
  const isSyncAllowed = useIsSyncAllowed();
  const database = useDatabase();

  useEffect(() => {
    const DELAY_MS = 1500;

    const readWriteCallback = debounce(
      async (event: TransactionEvent<keyof Schema>) => {
        if (isSyncAllowed && event.detail.storeNames.includes("armies")) {
          await trySync(database, pushPull);
        }
      },
      DELAY_MS,
      // `debounce` checks that the value of `this` has not changed between
      // different executions. Based on Sentry reports, this does seem to
      // happen in our case. I have absolutely no idea why. Binding `this`
      // to `null` squashes the issue.
      //
      // See https://github.com/sindresorhus/debounce/issues/8 for more detail
      // about the error that this check solves.
    ).bind(null);

    database.addEventListener("readwrite", readWriteCallback);

    return () => {
      database.removeEventListener("readwrite", readWriteCallback);
    };
  }, [database, isSyncAllowed]);
}

/**
 * Performs pull/push/pull synchronization with the cloud.
 *
 * Note that this hook uses SWR under the hood, so data sync is performed
 * on mount, and re-synchronized on window focus as per the default SWR
 * behaviour.
 */
function usePullPushPull() {
  const isLoggedIn = !!useCurrentUser();
  const isSyncAllowed = useIsSyncAllowed();
  const openRequest = useDatabaseOpenRequest();

  const sync = useCallback(async () => {
    // Do not attempt to sync until database is opened and user is logged in.
    if (!openRequest.isSuccess || !isLoggedIn || !isSyncAllowed) {
      return;
    }

    await trySync(openRequest.value, pullPushPull);
  }, [isLoggedIn, openRequest, isSyncAllowed]);

  // When the app initially mounts, the database will be in the closed state.
  // We have to make sure that SWR retries the fetch after the open request
  // succeeds, as only then we can attempt to synchronise.
  const key = `/api/sync:${openRequest.type}${isSyncAllowed}`;
  useSWR(key, sync);
}

export function SyncHandler(props: { children: ReactNode }) {
  usePullPushPull();
  usePushOnReadWrite();

  return <>{props.children}</>;
}
