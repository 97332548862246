import { ReactNode, useCallback, useEffect } from "react";
import useSWR from "swr";
import { client } from "../client";
import { useCurrentUser } from "../store/hooks";

function useExperimentalFeatures() {
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser?.prefersScrollbarVisibility === "ALWAYS") {
      document.body.classList.add("prefers-scrollbar-visibility-always");
    }

    return () => {
      document.body.classList.remove("prefers-scrollbar-visibility-always");
    };
  }, [currentUser?.prefersScrollbarVisibility]);
}

export function SessionHandler(props: { children: ReactNode }) {
  useExperimentalFeatures();

  const getCurrentUser = useCallback(async () => {
    await client.getCurrentUser();
  }, []);

  useSWR("/v1/users/me", getCurrentUser);

  return <>{props.children}</>;
}
