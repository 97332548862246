export type HistoryState = {
  emailValue?: string;
};

/**
 * Get's window history state with correct typings.
 *
 * Note that this is not a reactive value. It will not trigger re-renders if
 * it is changed.
 */
export function getHistoryState() {
  return window.history.state as HistoryState | null;
}
