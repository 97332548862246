import { HobgoblinDatabase } from "@/lib/db";
import { Failure, Pending, Success } from "@indietabletop/appkit/async-op";
import { caughtValueToString } from "@indietabletop/appkit/caught-value";
import { useContext, useEffect, useState } from "react";
import { DatabaseContext, DatabaseOpenRequest } from "./context";
import { QueryOp } from "./types";

export function useDatabase() {
  return useContext(DatabaseContext);
}

export function useDatabaseOpenRequest() {
  return useContext(DatabaseOpenRequest);
}

// eslint-disable-next-line @typescript-eslint/ban-types
const cache = new Map<string, unknown>();

export function useQuery<Output>(
  /**
   * `query` must have stable identity.
   *
   * Make sure you use `useCallback` or a module-scoped function.
   */
  query: (db: HobgoblinDatabase) => Promise<Output>,

  cacheKey?: string,
): QueryOp<Output> {
  const openRequest = useDatabaseOpenRequest();
  const [queryOp, setOp] = useState<QueryOp<Output>>(new Pending());

  useEffect(() => {
    async function runQuery() {
      if (openRequest.isSuccess) {
        try {
          const data = await query(openRequest.value);
          const success = new Success(data);
          setOp(success);

          if (cacheKey) {
            cache.set(cacheKey, success);
          }
        } catch (error) {
          const failure = new Failure({
            type: "QUERY_ERROR" as const,
            error: caughtValueToString(error),
          });

          setOp(failure);
          if (cacheKey) {
            cache.set(cacheKey, failure);
          }
        }
      }
    }

    const db = openRequest.valueOrNull();

    db?.addEventListener("readwrite", runQuery);
    window.addEventListener("focus", runQuery);
    void runQuery();

    return () => {
      db?.removeEventListener("readwrite", runQuery);
      window.removeEventListener("focus", runQuery);
    };
  }, [cacheKey, openRequest, query]);

  return openRequest.flatMap(() => {
    if (cacheKey) {
      const lastResult = cache.get(cacheKey) as QueryOp<Output> | undefined;

      if (lastResult && queryOp.isPending) {
        return lastResult;
      }
    }

    return queryOp;
  });
}
