import { config } from "@/config";
import { Army } from "@/domain/army";
import { ArmyData } from "@/schema/latest";
import { Button } from "@/ui/button";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDatabase } from "@/ui/database-provider";
import { useGameContentLookup } from "@/ui/game-content-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useRouter } from "@/ui/hooks/use-router";
import { button, primaryTheme } from "@/ui/utils.css";
import { DialogStore } from "@ariakit/react";
import { caughtValueToString } from "@indietabletop/appkit/caught-value";
import { useAsyncOp } from "@indietabletop/appkit/use-async-op";
import { useEffect } from "react";
import { useParams } from "wouter";
import { ArmyDetailView, ArmyPageLayout } from "../army-detail-page";
import { ArmyProvider } from "../army-provider";
import { useArmyOp } from "../army-provider/context";
import { UnitDetailView } from "../unit-detail-page";

type TransportError = { type: "FETCH_ERROR"; error: string };

export function useFetchSharedArmy(shareId: string) {
  const game = useGameContentLookup();
  const { op, setSuccess, setFailure } = useAsyncOp<
    ArmyData,
    TransportError | { type: "ARMY_NOT_FOUND"; message?: string }
  >();

  useEffect(() => {
    async function getSharedArmy() {
      try {
        const response = await fetch(
          `${config.ITC_API_ORIGIN}/hobgoblin/snapshots/${shareId}`,
        );

        switch (response.status) {
          case 200: {
            const data: ArmyData = await response.json();
            setSuccess(data);
            return;
          }

          case 404: {
            setFailure({
              type: "ARMY_NOT_FOUND",
              message: "Shared army with the specified ID was not found.",
            });
            return;
          }

          default: {
            setFailure({
              type: "FETCH_ERROR",
              error: "Could not fetch Shared Army.",
            });
            return;
          }
        }
      } catch (error) {
        console.error(error);

        setFailure({
          type: "FETCH_ERROR",
          error: caughtValueToString(error),
        });
      }
    }

    void getSharedArmy();
  }, [shareId, setSuccess, setFailure, game]);

  return op;
}

function DuplicateArmyDialog(props: { store: DialogStore; army: Army }) {
  const setLocation = useRouter();
  const database = useDatabase();

  return (
    <ConfirmDialog
      store={props.store}
      heading="Duplicate Army?"
      message={`${props.army.name} will be copied over to your armies.`}
      submit="Duplicate"
      submitTheme={primaryTheme}
      onSubmit={async () => {
        const armyId = await database.createArmy(props.army.data);
        setLocation(`~/army/a/${armyId}`);
      }}
    />
  );
}

function DuplicateArmyButton() {
  const armyOp = useArmyOp();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && armyOp.isSuccess && (
        <DuplicateArmyDialog store={store} army={armyOp.value} />
      )}
      <Button className={button} onClick={setOpen} style={primaryTheme}>
        Duplicate Army
      </Button>
    </>
  );
}

export function SharedArmyPages() {
  const params = useParams<{ shareId: string }>();
  const result = useFetchSharedArmy(params.shareId);

  return (
    <ArmyProvider armyDataOp={result}>
      <ArmyPageLayout
        armyTitle="Shared Army"
        armyDetail={
          <ArmyDetailView
            headerLabel={"Shared Army"}
            stickyButton={<DuplicateArmyButton />}
          />
        }
        unitTitle="Shared Unit"
        unitDetail={<UnitDetailView headerLabel="Shared Unit" readOnly />}
      />
    </ArmyProvider>
  );
}
