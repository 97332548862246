import { config } from "@/config";
import { ArmyData } from "@/schema/latest";
import { useDatabase } from "@/ui/database-provider";
import { useRuleset } from "@/ui/game-content-provider";
import {
  AsyncOp,
  Failure,
  Pending,
  Success,
} from "@indietabletop/appkit/async-op";
import { caughtValueToString } from "@indietabletop/appkit/caught-value";
import { useEffect, useState } from "react";
import { mask, object, string } from "superstruct";

async function createShare(armyData: ArmyData) {
  const response = await fetch(`${config.ITC_API_ORIGIN}/hobgoblin/snapshots`, {
    method: "POST",
    body: JSON.stringify(armyData),
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok) {
    throw new Error("Couldn't create share link. (Response failed)");
  }

  return mask(await response.json(), object({ share_id: string() }));
}

function getShareLink(shareId: string) {
  return `${window.origin}/army/s/${shareId}`;
}

/**
 * Provided {@link ArmyData}, returns a {@link AsyncOp} of Share URL, creating
 * the URL if necessary.
 *
 * Note that a Share ID already exists on the supplied army data, the will be
 * no async operation — the data will be simple returned as success.
 */
export function useEnsureShareLink(armyData: ArmyData) {
  const { share_id: shareId } = armyData;
  const ruleset = useRuleset();
  const database = useDatabase();
  const [result, setShareLink] = useState<AsyncOp<string, string>>(
    shareId ? new Success(getShareLink(shareId)) : new Pending(),
  );

  useEffect(() => {
    if (!armyData.share_id) {
      createShare(armyData).then(
        async (data) => {
          // Save Share ID
          await database.setArmyShareId(armyData.id, data.share_id);

          // Report Success
          setShareLink(new Success(getShareLink(data.share_id)));
        },
        (error) => {
          setShareLink(new Failure(caughtValueToString(error)));
        },
      );
    }
  }, [database, ruleset.version, armyData]);

  return result;
}
